import { Button, Tag } from "antd";
import React from "react";
import SubscriptionCardTemplate from "./SubscriptionCardTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";

export default function SubscriptionBusinessAppsCard() {
  return (
    <SubscriptionCardTemplate
      title="CTOT Apps for Business"
      subtitle={
        <>
          from
          <br />
          €1,79/month
        </>
      }
      subtitleSmall={
        <>
          per user, min 20 users.
          <br />
          Price decreases as volume increases for large companies.
          <br />
          <Tag color="#87d068" style={{fontWeight: 500, marginTop: 7}}>All included. No extra cost.</Tag>
        </>
      }
      headerAddon={
        <Button
          onClick={() => window.location.assign("mailto:contact@ctot.app")}
          type="primary"
        >
          Contact us to get started
        </Button>
      }
      descriptionTop={<>Add as many users from your organisation.</>}
      description={
        <div>
          Perfect for airlines, handling companies, airport operators willing to
          start without hassle.
          <br />
        </div>
      }
      points={[
        <>
          <FontAwesomeIcon
            icon={faApple}
            style={{ marginRight: 10, width: 14 }}
          />
          iOS app for iPad and iPhone
        </>,
        <>
          <FontAwesomeIcon
            icon={faAndroid}
            style={{ marginRight: 10, width: 14 }}
          />
          Android app
        </>,

        "Unlimited requests from our apps",
        "Easy user management",
        "API access for user management",
        "Automatic invoices based on user number",
        "No management fees",
        "Seamless integration for your users",
        "SSO integration",
        "Email support for the administrator",
      ]}
    />
  );
}
