import React, { useEffect, useState } from "react";

import Markdown from "markdown-to-jsx";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import { NavLink, useParams } from "react-router-dom";

import _ from "lodash";
import { Link } from "react-router-dom";
import OpenApiViewer from "./OpenApiViewer.js";

let menuItems = [
  {
    label: "Getting started",
    //docId: "introduction",
    children: [
      {
        label: "Who is eligible",
        docId: "business-eligibility",
      },
      {
        label: "Process to subscribe",
        docId: "business-subscribe",
      },
      {
        label: "Business account",
        docId: "business-introduction",
      },
      {
        label: "Payment options",
        docId: "business-payment",
      },
    ],
  },
  {
    label: "Use CTOT",
    children: [
      {
        label: "Add your users",
        docId: "business-console",
      },
      {
        label: "iOS and Android app",
        docId: "business-apps",
      },
    ],
  },
  {
    label: "Developers",
    children: [
      {
        label: "Getting started",
        docId: "business-api",
      },
      {
        label: "Users API",
        docId: "business-api-users",
      },
      {
        label: "Flight API",
        docId: "business-api-flight",
        hidden: true,
      },
      {
        label: "URL Schemes",
        docId: "business-url-schemes",
      },
    ],
  },
];
/* let docIds = _.flatMapDeep(menuItems, (ele) =>
  ele.children
    ? _.map(ele.children, (child) => ({ ...child }))
    : ele
); */

var flatten = function (item) {
  return [item, _.flatMapDeep(item.children, flatten)];
};

let docIds = _.flatMapDeep(menuItems, flatten);

const CodeBlock = ({ className, children }) => {
  let lang = "text"; // default monospaced text
  if (className && className.startsWith("lang-")) {
    lang = className.replace("lang-", "");
  }
  return (
    <SyntaxHighlighter
      language={lang}
      //style={CodeStyle}
    >
      {children}
    </SyntaxHighlighter>
  );
};

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({ children, ...rest }) => {
  if ("type" in children && children["type"] === "code") {
    return CodeBlock(children["props"]);
  }
  return <pre {...rest}>{children}</pre>;
};

export default function Docs({ ...rest }) {
  const { docId } = useParams();

  const [mardown, setMarkdown] = useState("");
  const currentSection = _.find(docIds, { docId }) || {
    docId: "introduction",
  };

  useEffect(() => {
    const start = async () => {
      const { default: md } = await import(
        `./markdown/${currentSection.docId}.md`
      );

      fetch(md)
        .then((response) => response.text())
        .then((text) => {
          setMarkdown(text);
        });
    };
    start();
  });

  return (
    <div className="ctot-docs ctot-docs-container" id="top">
      <div className="ctot-docs-container-menu">
        <DocsMenu />
      </div>

      <div className="ctot-docs-container-content">
        <Markdown
          children={mardown}
          remarkPlugins={[remarkGfm]}
          options={{
            overrides: {
              pre: PreBlock,
              OpenApiViewer,
            },
          }}
        />
      </div>
    </div>
  );
}

const DocsMenu = () => (
  <div className="ctot-docs-menu">
    {menuItems.map((item, i) => {
      if (item.hidden) return null;
      else return <DocsMenuRec key={i} item={item} level={0} />;
    })}
  </div>
);

const DocsMenuRec = ({ item, level = 0 }) => {
  const children = _.get(item, "children");
  let className = children
    ? "ctot-docs-menu-item with-children"
    : "ctot-docs-menu-item";
  className = className + ` ctot-docs-menu-level-${level}`;

  if (item.hidden) return null;

  if (children)
    return (
      <div className="ctot-docs-menu-group">
        <div className="ctot-docs-menu-group-title">
          <Link to={`../docs/${children[0].docId}`} className={className}>
            {item.label}
          </Link>
        </div>

        <div className="ctot-docs-menu-group-content">
          {children.map((subItem, j) => {
            if (subItem.hidden) return null;
            else
              return <DocsMenuRec item={subItem} key={j} level={level + 1} />;
          })}
        </div>
      </div>
    );
  else {
    return (
      <div className="">
        <NavLink
          to={`../docs/${item.docId}`}
          className={({ isActive }) =>
            isActive ? `${className} active` : className
          }
        >
          {item.label}
        </NavLink>
      </div>
    );
  }
};
