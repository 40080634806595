import { gql } from "@apollo/client";

export const flightsSummariesGQL = gql`
  query flightsSummaries($needle: String!, $filter: [String]) {
    flightsSummaries(needle: $needle, filter: $filter) {
      uniqId
      depIcao
      arrIcao
      eobt
      callsign
      ctot
      registration
      airline {
        logoUrl
      }
      eobt
      tsat
      ctot
      tobt
      depProc
      depStand

      suspensionStatus
      flightState

      mostPenalisingRegulation
      mostPenalisingRegulationCause {
        RegulationReason
        RegulationLocationCategory
        iataDelayCode
      }
    }
  }
`;

export const flightGQL = gql`
  query flight($uniqId: String) {
    flight(uniqId: $uniqId) {
      _errorMessage
      _errorCode
      _status {
        title
        message
        type
        code
        link
        linkTitle
        closable
        navigateTo
        navigateToTitle
      }
      updatedAt
      uniqId

      depAirportType
      eobt
      depIcao
      arrIcao
      callsign
      registration

      nextFlight {
        depIcao
        arrIcao
        callsign
        eobt
      }

      tsat
      earliestCtot
      latestCtot
      eta
      taxiDuration
      ceta
      tobt

      depRwy
      depStand
      depTerminal
      depProc

      eetDuration

      altIcaos

      arrProc
      arrProcNm
      arrRwy
      arrStand
      arrTerminal
      arrTaxiDuration

      etot
      ctot
      ctotExtended
      cdmEobt
      taxiDuration
      taxiSource
      targetTimeOverFix
      flightState
      mostPenalisingRegulation
      mostPenalisingRegulationCause {
        iataDelayCode
        RegulationLocationCategory
      }
      regulations {
        id
        locationId
        type
      }
      suspensionStatus
      suspensionInfo
      icaoRoute
      readyStatus {
        readyForImprovement
        readyToDepart
        revisedTaxiTime
      }
    }
  }
`;

export const flightsSearchGQL = gql`
  query getFlightSearch($needle: String!, $filter: [String!]) {
    flightsSearch(needle: $needle, filter: $filter) {
      id
      keys {
        aerodromeOfDeparture
        aerodromeOfDestination
        aircraftId
        estimatedOffBlockTime
        airFiled
        nonICAOAerodromeOfDeparture
        nonICAOAerodromeOfDestination
      }
      flight {
        lastUpdate
        _errorMessage
        _errorCode
        _status {
          title
          message
          type
          code
          link
          linkTitle
          closable
          navigateTo
          navigateToTitle
        }

        estimatedTakeOffTime
        calculatedTakeOffTime
        cdmEstimatedOffBlockTime
        taxiTime
        currentDepartureTaxiTimeAndProcedure {
          taxiTime
        }
        flightState
        mostPenalisingRegulation
        mostPenalisingRegulationCause {
          iataDelayCode
          RegulationReason
          RegulationLocationCategory
        }
        suspensionStatus
        aircraftAddress
        filedRegistrationMark
        cdm {
          status
          AirportType
          info {
            departureProc {
              id
            }
            departureTerminal
            departureApronStand
            targetStartupApprovalTime
            targetOffBlockTime
          }
        }
      }
    }
  }
`;

export const userMinimalGQL = gql`
  query user {
    user {
      email
      workEmail
    }
  }
`;

export const userWithContractsGQL = gql`
  query user {
    user {
      email
      currentSubscription {
        isActive
        type
        lastExpirationDate
        displayName
      }
      contracts {
        _id
        domains
        users
        isActive
        displayName
        keys {
          key
          createdAt
        }
        webhooks {
          _id
          url
          key
          description
          createdAt
        }
        flightApi
      }
      stripe {
        subscriptions {
          id
          current_period_end
          cancel_at_period_end
          canceled_at
          status
          plan {
            id
            amount
            currency
            interval
            interval_count
            active
          }
        }
      }
    }
  }
`;

export const GetFlightGQL = gql`
  query getFlight($flightId: FlightIdInput!) {
    flight(flightId: $flightId) {
      lastUpdate
      _errorMessage
      _errorCode

      estimatedTakeOffTime
      calculatedTakeOffTime
      cdmEstimatedOffBlockTime
      taxiTime
      currentDepartureTaxiTimeAndProcedure {
        taxiTime
      }
      flightState
      mostPenalisingRegulation
      mostPenalisingRegulationCause {
        iataDelayCode
        RegulationLocationCategory
      }
      suspensionStatus
      suspensionInfo
      slotIssued
      hasOtherRegulations
      regulationLocationsJSON
      regulations {
        id
        locationId
        type
        geoJson
        firGeoJson
      }
      filedRegistrationMark
      icaoRoute
      aircraftAddress
      lastKnownPosition {
        timeOver
        position {
          latitude
          longitude
        }
        level {
          unit
          level
          ground
        }
      }
      readyStatus {
        readyForImprovement
        readyToDepart
        revisedTaxiTime
      }
      cdm {
        status

        AirportType
        info {
          departureProc {
            id
          }
          registrationMark
          targetStartupApprovalTime
          targetOffBlockTime
        }
      }
    }
  }
`;

export const preloginGQL = gql`
  query prelogin($email: String!) {
    prelogin(email: $email) {
      suggestion
      domain
    }
  }
`;
