import { Button } from "antd";
import React from "react";
import SubscriptionCardTemplate from "./SubscriptionCardTemplate";

export default function SubscriptionBusinessApiCard() {
  return (
    <SubscriptionCardTemplate
      title="Flight API"
      subtitle={
        <>
          From
          <br />
          €0,14/flight*
        </>
      }
      subtitleSmall={
        <>
          *one fee per flight followed, <br />
          unlimited notifications
        </>
      }
      headerAddon={
        <Button
          onClick={() => window.location.assign("mailto:support@ctot.app")}
          title="Contact us"
          //href="https://dashboard.ctot.app/business"
        >
          Contact us
        </Button>
      }
      //amount="from 1.59€ per user per month"
      descriptionTop={
        <>
          Access our API and build your own integration, either with or without
          coding.
        </>
      }
      description={
        <div>Perfect for companies having custom needs. For example, integrate CTOT data into an existing EFB module, or use webhooks or automations.</div>
      }
      points={[
        "Unlimited API requests",
        "Webhook notifications",
        "Zapier integrations with zero code",
        "Automatic payments every month",
        "No management fees",
        "Email support for the administrator",
      ]}
    />
  );
}
